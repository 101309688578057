<template>
	<div class="special">
		<div class="banner-size">
			<div class="banner-limit banner-info">
				<span class="banner-fMax">{{ title }}</span>
				<span class="banner-fMin banner-other">送政策、送服务，助推企业发展</span>
			</div>
		</div>
		<div class="special-container width-limit" ref="declareContainer">
			<div class="base-contianer">
				<div class="b-title">国家政策</div>
				<el-table :data="fczcList" style="width: 100%" @row-click="tableRowClick">
					<el-table-column prop="title" label="标题" width="1000"> </el-table-column>
					<el-table-column prop="time" label="日期" width="180"> </el-table-column>
				</el-table>
			</div>
			<div class="base-contianer">
				<div class="b-title">专项</div>
				<div class="b-content">
					<div class="item" v-for="(item, i) in zxList" :key="i">
						<DeclareCell v-if="i < 9" :item="item" @clickCell="clickCell" :key="item.id" :collectItem="isItemCollect(item)"
						 :isShowText="false" @clickIsFollow="clickIsFollow"></DeclareCell>
					</div>
				</div>
			</div>
			<div class="bsfw-title">办事服务</div>
			<div class="base-contianer">
				<div class="b-title">企业服务</div>
				<div class="b-content">
					<div class="card-to" v-for="(item, i) in qyfwLIst" :key="i">
						<div>
							<p class="card-title">{{ item.title }}</p>
							<p class="card-content">{{ item.content }}</p>
						</div>
						<div class="card-bottom" @click="qyfwClick(item)">{{ item.ts }} →</div>
					</div>
				</div>
			</div>
			<div class="base-contianer">
				<div class="b-title">个人服务</div>
				<div class="b-content">
					<div class="card-to" v-for="(item, i) in grfwLIst" :key="i">
						<div>
							<p class="card-title">{{ item.title }}</p>
							<p class="card-content">{{ item.content }}</p>
						</div>
						<div class="card-bottom" @click="grfwClick(item)">{{ item.ts }} →</div>
					</div>
				</div>
			</div>
			<!--登录提示弹窗-->
			<el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
			  <span>需要登录才能收藏，是否登录？</span>
			  <span slot="footer" class="dialog-footer">
			    <el-button @click="dialogVisible = false">取 消</el-button>
			    <el-button type="primary" @click="gotoLogin">确 定</el-button>
			  </span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import DeclareCell from "@/components/declareCell"; // 申报项目，智能推荐卡片

	export default {
		components: {
			DeclareCell,
		},
		computed: {},
		data() {
			return {
				title: "",
				/* 扶持政策 */
				fczcList: [{
						title: "广东省基础设施投资基金管理办法",
						time: "2018-12-30",
					},
					{
						title: "广东省人民政府关于印发《广东省优化口岸营商环境促进跨境贸易便利化措施的通知》",
						time: "2019-03-14",
					},
					{
						title: "广东省财政厅、国家税务总局广东省税务局印发《关于贯彻落实粤港澳大湾区个人所得税优惠政策的通知》",
						time: "2019-06-22",
					},
					{
						title: "《广东省人民政府印发关于进一步促进科技创新若干政策措施的通知》",
						time: "2018-12-25",
					},
					{
						title: "广东省基础设施投资基金管理办法",
						time: "2018-12-30",
					},
					{
						title: "广东省人民政府关于印发《广东省优化口岸营商环境促进跨境贸易便利化措施的通知》",
						time: "2019-03-14",
					},
					{
						title: "广东省财政厅、国家税务总局广东省税务局印发《关于贯彻落实粤港澳大湾区个人所得税优惠政策的通知》",
						time: "2019-06-22",
					},
					{
						title: "《广东省人民政府印发关于进一步促进科技创新若干政策措施的通知》",
						time: "2018-12-25",
					},

				],
				/* 专项申报 */
				zxList: [],
				/* 企业服务 */
				qyfwLIst: [{
						title: "办理企业注册",
						path: "http://www.gdzwfw.gov.cn/portal/legal/mattersLv2/hot?region=440000&lv2code=9300000001",
						content: "",
						ts: "立即办理",
					},
					{
						title: "公司机构备案",
						path: "http://www.gdzwfw.gov.cn/portal/legal/mattersLv2/hot?region=440000&lv2code=9300000012",
						content: "",
						ts: "立即查看",
					},
					{
						title: "税务登记",
						path: "http://www.gdzwfw.gov.cn/portal/legal/mattersLv2/hot?region=440000&lv2code=9300000004",
						content: "",
						ts: "立即办理",
					},
				],
				/* 个人服务 */
				grfwLIst: [{
						title: "就业创业",
						content: "",
						path: 'http://www.gdzwfw.gov.cn/portal/personal/theme?region=440000&catalogCode=001001005',
						ts: "查看",
					},
					{
						title: "办理港澳通通行证",
						content: "",
						path: 'http://www.gdzwfw.gov.cn/portal/v2/search?region=440000&keyword=%E6%B8%AF%E6%BE%B3%E5%B1%85%E6%B0%91%E5%B1%85%E4%BD%8F%E8%AF%81&areaCode=440000&departmentCode=&onlyCorrespondingLevel=0&type=&guideGroup=true',
						ts: "办理",
					},
					{
						title: "港澳高层次人才认定",
						content: "",
						path: 'http://pro.gdstc.gd.gov.cn/egrantweb/',
						ts: "办理",
					},
					{
						title: "港珠澳大桥同行",
						content: "",
						path: 'https://www.hzmb.org/',
						ts: "办理",
					},
					{
						title: "广深港高铁订票",
						content: "",
						path: 'https://www.12306.cn/index/index.html',
						ts: "办理",
					},
					{
						title: "婚姻",
						content: "",
						path: 'http://www.gdzwfw.gov.cn/portal/personal/mattersLv2/hot?region=440000&lv2code=7700000006',
						ts: "办理",
					},
					{
						title: "住房公积金",
						content: "",
						path: 'http://www.gdzwfw.gov.cn/portal/personal/mattersLv2/hot?region=440000&lv2code=7700000013',
						ts: "办理",
					},
				],
				//收藏列表
				collectList:[],
				//弹窗
				dialogVisible:false
			};
		},
		mounted() {
			let item = JSON.parse(sessionStorage.getItem("specialColumn"));
			console.log("item", item);
			if (item && item.title) {
				this.title = item.title;
			}
			this.loadData();
			this.getCollectList()
		},
		methods: {
			loadData() {
				let param = {
					pageNum: 1,
					pageSize: 20,
				};
				let url = "/dev-api/basicinfo/baseitem/xmsbList";
				this.$nextTick(() => {
					// 以服务的方式调用的 Loading 需要异步关闭
					this.$httpApi.get(url, param).then((res) => {
							console.log("项目列表:", res);
							if (res.code == 200) {
								this.zxList = res.rows;
							}
						})
						.catch((err) => {

						});
				});
			},
			/**
			 * 登录提示框点击确定登录，跳转登录页面
			 */
			gotoLogin() {
			  console.log("跳转登录页面");
			  this.$router.push({
			    path: "/login",
			  });
			},
			//查询所有的收藏列表,之后需要比对事项的收藏状态
			getCollectList() {
			  if (this.$store.getters.getUserInfo) {
			    let identify = this.$store.getters.getUserInfo.shxydm;
			    let param = {
			      identify: identify,
			      type: "1,2,3,4,5,6",
			      pageNum: 1,
			      pageSize: 100,
			    };
			    let url = "/dev-api/collection/list";
			
			    this.$httpApi
			      .get(url, param)
			      .then((res) => {
			        if (res.code == 200) {
			          this.collectList = res.rows;
			        }
			      })
			      .catch((err) => {});
			  }
			},
			//比对收藏列表,判断是否收藏
			isItemCollect(item) {
			  let list = this.collectList;
			
			  for (let i = 0; i < list.length; i++) {
			    let collectItem = list[i];
			    if (item.id == collectItem.collectionId) {
			      console.log(item.name + ":已收藏");
			      return collectItem;
			    }
			  }
			  return null;
			},
			// 判断是否登录
			clickIsFollow(item) {
			  //判断是否登录
			  if (this.$store.getters.getUserInfo) {
			    //登录
			  } else {
			    //未登录
			    this.dialogVisible = true;
			  }
			},
			/**
			 * 专项 卡片点击事件
			 * @param {object} item 卡片信息
			 * @return 转跳到专项详细
			 */
			clickCell(item) {
				this.$store.commit("updateCurrentGuideId", item.id);
				if (["1", "2"].indexOf(item.itemtype) > -1) {
					this.$router.push({
						path: "/guideShenpi",
						query: {
							name: item.name,
						},
					});
				} else {
					this.$router.push({
						path: "/guideSuqiu",
					});
				}
				// 修改 header nav 状态
				this.$store.commit("updateHNavAction", "/projectList");
			},
			/**
			 * 复工复产
			 */
			qyfwClick(e) {
				let href = e.path
				window.open(href, '_blank')
			},
			grfwClick(e) {
				let href = e.path
				window.open(href, '_blank')
			},
			gotoHandle() {
				let href = 'http://apps.gdzwfw.gov.cn/yqfk/business-matters.html '
				window.open(href, '_blank')

			},
			tableRowClick() {
				this.$message({
					message: "功能开发中, 敬请期待",
					type: "warning",
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/styles/index.scss";

	/* 头部 */
	.special {

		// background: #fff;
		.banner-size {
			background: url("../../assets/images/icon_banner_declare.png");
		}

		.banner-info {
			height: 100%;
			display: flex;
			align-items: center;

			.banner-other {
				margin-left: 15px;
			}
		}
	}

	/* 主体部分 */
	.special-container {
		background-color: #ffffff;

		.d-title {
			font-size: 24px;
			color: #000;
			font-weight: 600;
			padding-bottom: 30px;
			margin-right: 30px;
		}

		.d-btns {
			padding: 40px 0;
		}
	}

	.base-contianer {
		width: 100%;
		min-height: 200px;

		.b-title {
			padding: 10px 0;
			margin-top: 20px;
			margin-bottom: 20px;
			border-bottom: 4px solid #000;
			color: #000;
			font-size: 20px;
			font-weight: 600;
		}

		.b-content {
			width: 100%;
			display: flex;
			flex-flow: row wrap;

			.item {
				width: 32%;
				box-sizing: border-box;
				margin-bottom: 16px;
				margin-right: 16px;
			}

			.card-to {
				width: 31%;
				height: 148px;
				box-sizing: border-box;
				margin-right: 10px;
				margin-bottom: 10px;
				// border: 1px solid #333;
				box-shadow: 0 0 10px 1px rgba(#333, 0.1);
				padding: 14px;
				display: flex;
				flex-flow: column nowrap;
				justify-content: space-between;

				.card-title {
					font-size: 24px;
					font-weight: bold;
					color: #000;
				}

				.card-content {
					font-size: 14px;
					color: #000;
				}

				.card-bottom {
					font-size: 14px;
					color: #559df5;
					cursor: pointer;
				}
			}
		}

		/* 标题 */
		.el-table__header-wrapper {}

		/* 内容 */
		.el-table__body-wrapper {

			/* 状态栏 */
			// .el-table_1_column_1 {
			//     color: $color-danger !important;
			// }
			/* 横向属性 */
			.el-table__row {
				height: 60px;
				cursor: pointer;

				&:hover {
					// height: 70px;
					// font-size: $font-size18;
					font-weight: bold;

					.el-table_1_column_2 {
						color: $color-primary !important;
					}
				}
			}
		}
	}

	/* 办事服务 */
	.bsfw-title {
		text-align: center;
		font-size: 32px;
		font-weight: bold;
		color: #000;
		margin: 40px 0;
	}

	/* 最下方图片 */
	.bottom-img {
		width: 100%;
		height: 200px;
		margin: 40px 0 0 0;
		padding: 20px 80px;
		background: #213e5f;
		color: #fff;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;

		.left {
			width: 400px;

			.title {
				font-size: 31px;
			}

			.content {
				font-size: 14px;
			}
		}

		.right {
			font-size: 12px;
			border: 1px solid #fff;
			border-radius: 4px;
			padding: 6px 30px;
		}
	}
</style>
